import { FAQS_FOR_GENERATOR } from "services/pricing/faqs-service";
import { XS, MD, LG } from "constants/sizes";
import { SNOW_GREY_THEME_ID } from "segments/segments-service";
export default {
    title: "pricingPage.faq",
    titleSize: LG,
    questionSize: XS,
    answerSize: MD,
    questions: FAQS_FOR_GENERATOR,
    theme: SNOW_GREY_THEME_ID,
    hideQuestionsJumpToShortCut: false
};
