const BASE_FAQS_NO_PRODUCTS = [
    {
        question: "pricingPage.faq.new.cost.question",
        answer: "pricingPage.faq.new.cost.answer"
    },
    {
        question: "pricingPage.faq.new.monthly.question",
        answer: "pricingPage.faq.new.monthly.answer"
    },
    {
        question: "pricingPage.faq.new.rightPlanForMe.question",
        answer: "pricingPage.faq.new.rightPlanForMe.answer"
    },
    {
        question: "pricingPage.faq.new.howDoesOurPricingWork.question",
        answer: "pricingPage.faq.new.howDoesOurPricingWork.answer"
    }
];
const BASE_FAQS = [
    {
        question: "pricingPage.faq.new.changedMyMind.question",
        answer: "pricingPage.faq.new.changedMyMind.answer"
    },
    {
        question: "pricingPage.faq.new.discount.question",
        answer: "pricingPage.faq.new.discount.answer"
    },
    {
        question: "pricingPage.faq.new.students.question",
        answer: "pricingPage.faq.new.students.answer"
    },
    {
        question: "pricingPage.faq.new.nonProfits.question",
        answer: "pricingPage.faq.new.nonProfits.answer"
    },
    {
        question: "pricingPage.faq.new.manageBilling.question",
        answer: "pricingPage.faq.new.manageBilling.answer"
    },
    {
        question: "pricingPage.faq.new.changePlan.question",
        answer: "pricingPage.faq.new.changePlan.answer"
    },
    {
        question: "pricingPage.faq.new.security.question",
        answer: "pricingPage.faq.new.security.answer"
    },
    {
        question: "pricingPage.faq.new.paymentMethods.question",
        answer: "pricingPage.faq.new.paymentMethods.answer"
    },
    {
        question: "pricingPage.faq.new.mobile.question",
        answer: "pricingPage.faq.new.mobile.answer"
    }
];
export const FAQS = [
    ({ hasFreeTier, hasStudentPlan })=>{
        const question = "pricingPage.faq.new.freeVersion.question";
        if (hasStudentPlan) {
            return null;
        }
        if (hasFreeTier) {
            return {
                question,
                answer: "pricingPage.faq.new.freeVersion.answer.for_free_tier"
            };
        }
        return {
            question,
            answer: "pricingPage.faq.new.freeVersion.answer"
        };
    },
    ...BASE_FAQS_NO_PRODUCTS,
    ...BASE_FAQS
];
export const FAQS_FOR_GENERATOR = [
    {
        question: "pricingPage.faq.new.freeVersion.question",
        answer: "pricingPage.faq.new.freeVersion.answer"
    },
    ...BASE_FAQS_NO_PRODUCTS,
    ...BASE_FAQS
];
export const FAQS_FOR_PRODUCTS = [
    {
        question: "pricingPage.faq.new.rightPlanForMe.question",
        answer: "pricingPage.faq.products.rightPlanForMe.answer"
    },
    {
        question: "pricingPage.faq.products.whatIsWorkMgmt.question",
        answer: "pricingPage.faq.products.whatIsWorkMgmt.answer"
    },
    {
        question: "pricingPage.faq.products.whatUseProductsFor.question",
        answer: "pricingPage.faq.products.whatUseProductsFor.answer"
    },
    {
        question: "pricingPage.faq.products.howProductsWorkTogether.question",
        answer: "pricingPage.faq.products.howProductsWorkTogether.answer"
    },
    {
        question: "pricingPage.faq.products.diffBetweenWorkMgmtAndProducts.question",
        answer: "pricingPage.faq.products.diffBetweenWorkMgmtAndProducts.answer"
    },
    {
        question: "pricingPage.faq.new.howDoesOurPricingWork.question",
        answer: "pricingPage.faq.products.howPricingWork.answer"
    },
    ...BASE_FAQS
];
